import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory } from "react-router"
import { useTranslation } from 'react-i18next'

import storage from "../../../util/storage"
import GetDetailAndStoreItUseCase from '../../../../useCase/Folder/GetDetailAndStoreItUseCase'
import FetchFolderGateway from '../../../../gateway/Folder/FetchFolderGateway'
import LocalStorageGateway from '../../../../gateway/Form/LocalStorageGateway'
import useModal from "../../modal/useModal"
import PageLoader from "../../pageLoader/PageLoader"
import Converter from '../../../util/Converter'
import envVariable from "../../../util/envVariable"
import FolderActions from './FolderActions'
import FolderModal from './FolderModal'
import FolderDetails from './FolderDetails'
import { Props } from './types'
import {ErrorResponse} from '../../../../domain/Api/Type'
import ErrorModal from '../../modal/ErrorModal'

const FolderComponent: FunctionComponent<Props> = ({ handleDeleteFolder, FolderDetail, type }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const role = localStorage.getItem('role') ?? ''
  const [isShowing, toggle] = useModal()
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [showDocusignHistory, setShowDocusignHistory] = useState<boolean>(false)
  const allowDownloadDocument = (envVariable('REACT_APP_ALLOW_DOWNLOAD_DOCUMENT') === 'true')
  const [showEnvelopeHistoryModal, toggleEnvelopeHistory] = useModal()
  const [contentModal, setContentModal] = useState<JSX.Element | null>(null)
  const [modalSize, setModalSize] = useState<string>('modal')
  const [errorMessage, setErrorMessage] = useState<string|null>(null)

  useEffect(() => {
    if (error) {
        setTimeout(() => setError(false), 5000)
    }
  }, [error])


  const handleEdit = () => {
    storage.setFolderId(FolderDetail.id)
    storage.setFormId(FolderDetail.formId)
    storage.setDossierState(FolderDetail.status)
    const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())

    getDetailAndStoreItUseCase.execute(FolderDetail.id).then((response) => {
      if (response !== null) {
        history.push(response.url)
      }
    })
  }

  const handleDelete = () => {
    const folderGateway = new FetchFolderGateway()
    folderGateway.updateFolderStatusToCanceled(FolderDetail.id)
      .then(() => {
        handleDeleteFolder(FolderDetail.id)
        setError(false)
      })
      .catch((e) => {
        console.error(e)
        setError(true)
      })
  }

  const handleDownload = () => {
    setLoading(true)
    storage.setFolderId(FolderDetail.id)
    const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())

    getDetailAndStoreItUseCase?.downloadSignedDocument(FolderDetail.id)?.then((response) => {
      if (response !== null) {
        const url = URL.createObjectURL(response)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = `dossier-${String(FolderDetail.id)}.zip`
        a.click()
        window.URL.revokeObjectURL(url)
      }
      setLoading(false)
    })
      .catch((errors: ErrorResponse) => {
        errors.errors.forEach(error => {
          setErrorMessage(error.message)
        });
        setLoading(false)
      })
  }

  const handleDocuSignHistory = () => {
    setShowDocusignHistory(true)
    storage.setFolderId(FolderDetail.id)
    const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())

    getDetailAndStoreItUseCase?.getFolderDocuSignHistory(FolderDetail.id)?.then((response) => {
      setContentModal(buildModalContent(response))
      setShowDocusignHistory(false)
    })
      .catch((error) => {
        setShowDocusignHistory(false)
        if (error?.response?.data?.detail?.includes('ENVELOPE_DOES_NOT_EXIST')) {
          setErrorMessage(t('docusign.error-folder-not-found'))
        } else {
          setErrorMessage(t('docusign.error-content'))
        }
      })
  }

  const handleManageDossier = () => {
    storage.setFolderId(FolderDetail.id)
    storage.setFormId(FolderDetail.formId)
    history.push(`/manage-dossier/${FolderDetail.id}`)
  }

  const buildModalContent = (response) => {
    setModalSize('modal-xl')
    toggleEnvelopeHistory()

    if (response) {
      const resource = `data:application/pdfbase64,${response.envelopeHistoryData}`

      return (
        <div className="pj-modal-content-center">
          <iframe src={resource} width="100%" style={{ height: '80vh' }} />
        </div>
      )
    }

    return (
      <div className="pj-modal-content-center">
        <p className="notification notification--error">{t('docusign.error-folder-not-found')}</p>
      </div>
    )
  }

  const FolderStatuses = FolderDetail.status.split('|').map(status => t(`folder.state.${status}`))

  return (
    <>
      { errorMessage && <ErrorModal message={errorMessage} onClose={() => setErrorMessage(null)} /> }
      {showEnvelopeHistoryModal &&
        <FolderModal title={t('docusign.modal-preview-title')}
                     description=''
                     customClassName={modalSize}
                     hide={toggleEnvelopeHistory}>
          {contentModal}
        </FolderModal>
      }

      <div className="list__line" key={FolderDetail.id}>
        <FolderDetails
          FolderDetail={FolderDetail}
          error={error}
          FolderStatuses={FolderStatuses}
        />
        {isLoading && <PageLoader />}
        {showDocusignHistory && <PageLoader description={t('docusign.display-history-loader')} />}
        <FolderActions
          FolderDetail={FolderDetail}
          role={role}
          handleEdit={handleEdit}
          handleDownload={handleDownload}
          handleDocuSignHistory={handleDocuSignHistory}
          handleManageDossier={handleManageDossier}
          setIsPopoverOpen={setIsPopoverOpen}
          isPopoverOpen={isPopoverOpen}
          toggle={toggle}
          allowDownloadDocument={allowDownloadDocument}
          setError={setError}
          type={type}
        />
        {isShowing && <FolderModal title={t('folder.modal-cancellation.title')}
                                   description={t('folder.modal-cancellation.description')}
                                   hide={toggle}
                                   customClassName={`modal`}
                                   forceClose={false}>
          <div className="modal-container-buttons">
            <button type="button"
                    className="button-reset button-primary--outline button--medium button--width-fit"
                    onClick={toggle}
            >
              {t('folder.modal-cancellation.no-i-decline')}
            </button>
            <button type="button"
                    className="button-reset button-error--outline button--medium button--width-fit"
                    onClick={handleDelete}
            >
              {t('folder.modal-cancellation.yes-i-delete-subscription-folder')}
            </button>
          </div>
        </FolderModal>}
      </div>
    </>
  )
}

export default FolderComponent
